import { Breadcrumbs, Card, CardContent, Grid, Link, Typography } from '@mui/material';
import { ReactNode } from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { BreadcrumbItem } from 'interfaces/BreadCrumbItem';
import { Helmet } from 'react-helmet-async';

interface LayoutProps {
    pageTitle?: string;
    headerTitle?: string;
    children: ReactNode;
    breadcrumbMiddleItems?: [BreadcrumbItem];
    disableBreadcrumb?: boolean;
}

function generateBreadCrumbItem(item: BreadcrumbItem, key: number) {
    return (
        <div key={key}>
            {item.url ? (
                <Link underline="hover" color="inherit" href={item.url}>
                    {item.name}
                </Link>
            ) : (
                <span>{item.name}</span>
            )}
        </div>
    );
}

const Layout = ({ pageTitle, headerTitle, children, breadcrumbMiddleItems, disableBreadcrumb = false }: LayoutProps) => {
    let i = 1;
    const breadcrumbs = [
        generateBreadCrumbItem({ name: 'Home', url: '/' }, i++),
        ...(breadcrumbMiddleItems?.map((item) => {
            return generateBreadCrumbItem(item, i++);
        }) ?? []),
        generateBreadCrumbItem({ name: headerTitle ?? '' }, i++)
    ];
    return (
        <div>
            <Helmet>
                <title>{`HR | ${pageTitle}`}</title>
            </Helmet>
            {headerTitle && (
                <Card sx={{ marginBottom: 20 }}>
                    <CardContent>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <Typography variant="h3">{headerTitle}</Typography>
                            </Grid>
                            {!disableBreadcrumb && (
                                <Grid item>
                                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
                                        {breadcrumbs}
                                    </Breadcrumbs>
                                </Grid>
                            )}
                        </Grid>
                    </CardContent>
                </Card>
            )}
            {children}
        </div>
    );
};

export default Layout;
